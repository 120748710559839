/* Überschreibt die aktuelle validationEngine Funktion, und gibt für alle inputs,textarea und select einen ID*/
(function($) {
	var oldf = $.fn.validationEngine;
	var f = function()
	{
		$(this).find("input,textarea,select").each(function() {
			if (!$(this).attr("id"))	 {
				$(this).attr("id","input_" + UUID());
			}
		 });   
		return oldf.apply(this, arguments);
	};
	
	$.fn.validationEngine = f;
})(jQuery);


function UUID() {
	var i,
		c = "89ab",
		u = [];
	for (i = 0; i < 36; i += 1) {
		u[i] = (Math.random() * 16 | 0).toString(16);
	}
	u[8] = u[13] = u[18] = u[23] = "-";
	u[14] = "4";
	u[19] = c.charAt(Math.random() * 4 | 0);
	return u.join("");
}

jQuery(function($) {
    $.extend({
        serializeJSON: function(obj) {
            var t = typeof(obj);
            if(t != "object" || obj === null) {
                // simple data type
                if(t == "string") obj = '"' + obj.replace(/"/g,'\\"') + '"';
                return String(obj);
            } else {
                // array or object
                var json = [], arr = (obj && obj.constructor == Array);
 
                $.each(obj, function(k, v) {
                    t = typeof(v);
                    if(t == "string") v = '"' + v.replace(/"/g,'\\"') + '"';
                    else if (t == "object" & v !== null) v = $.serializeJSON(v)
                    json.push((arr ? "" : '"' + k.replace(/"/g,'\\"') + '":') + String(v));
                });
 
                return (arr ? "[" : "{") + String(json) + (arr ? "]" : "}");
            }
        }
    });
});

function makeQtipOptions(el) {
	var width = el.data("qtip-width") ? el.data("qtip-width") : 350;
	var my	= el.data("qtip-my") ? el.data("qtip-my") : 'left top';
	
	var def_options = {
		style: {
			classes:'qtip-self',
			width:width
		},
		content : {
			ajax : {}	
		},
		position: {
			viewport:$(window),
			my: 'left top'
		},
		show : {
			solo: true	
		},
		hide: {
		  fixed:true,
		   delay: 500
	    }
	};
	
	
	

	el.each(function() {
		var options = $.extend({}, def_options);
		
		var cont = $(this).find(".preview_content");
		if (cont.length == 0) {
			if ($(this).next().hasClass("preview_content")) {
				cont = $(this).next();
			}
		}
		
		if (cont.length > 0) {
			cont.addClass("no_hide");
			var d = $("<div/>");
			cont.appendTo(d);
			options.content.title = $(this).attr('title');
			options.content.text  = d.html();	
			cont.remove();
		} else if ($(this).attr('rel')) {
			options.content.text 	 = 'Loading...';
			options.content.ajax.url = $(this).attr('rel');
			options.content.title 	 = $(this).attr('title');
		} else {
			var a = ($(this).attr('title') ? $(this).attr('title') : "").split("|");
			options.content.title = a[0];
			options.content.text  = a[1];
		}
		
		$(this).qtip(options);
	});
	
}